<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<!-- 공급기업 지정 프로젝트 지원현황 -->
				<div class="list-box">
					<div class="tit">공급기업 지정 프로젝트 &gt; 소속인재 프로젝트 지원 현황</div>
					<div class="search-box interval">
						<SelectComp type="select" title="프로젝트 상태" list="4:제안중,2:수행예정,1:수행중,9:수행완료" :isAll="true" v-model="srchFilter.projectState" /><nbsp/>
						<SelectComp type="select" title="프로젝트명" list="1:프로젝트명,2:고객사명" v-model="srchFilter.searchGubun" />						
						<InputComp type="text" :placeholder="srchFilter.searchGubun ==  '1' ? '프로젝트명' : '고객사명'" v-model="srchFilter.srcTxt" @keyup.enter="selectIntvReqList('1')" />
						<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="selectIntvReqList('1')"/>
					</div>
					<div class="filter">
						<div class="filter_box int tech"> 
							<ul>
								<li @click="orderSort('1')"><img src="/images/icon_filter.png" alt="최신 등록순" :class="srchFilter.orderGubun == '1' ? 'filter' : ''"/><p>최신 등록순</p><span>ㅣ</span></li>
								<li @click="orderSort('2')"><img src="/images/icon_filter.png" alt="지원 적은순" :class="srchFilter.orderGubun == '2' ? 'filter' : ''"/><p>지원 적은순</p><span>ㅣ</span></li>
								<li @click="orderSort('3')"><img src="/images/icon_filter.png" alt="지원 많은순" :class="srchFilter.orderGubun == '3' ? 'filter' : ''"/><p>지원 많은순</p><span>ㅣ</span></li>
								<li @click="orderSort('4')"><img src="/images/icon_filter.png" alt="마감 임박순" :class="srchFilter.orderGubun == '4' ? 'filter' : ''"/><p>마감 임박순</p></li>
							</ul>
						</div>
					</div>

					<div class="Board type3">
						<table class="Board_type3 prj list-all" style="margin-left: 0;">
							<colgroup> 
								<col width="3%">
								<col width="*">
								<col width="12%">
								<col width="15%">
								<col width="10%">
								<col width="10%">
								<col width="18%">
							</colgroup>
							<thead>
								<tr>
									<th>NO</th>
									<th>프로젝트명</th>
									<th>고객사명</th>
									<th>개발기간</th>
									<th>등록일</th>
									<th>프로젝트 상태</th>
									<th>프로젝트 지원인재</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(project, index) in projectList" :key="index">
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td class="score prj-name">
										<div v-if="project.recrEndDateDiff >= 0 || project.projectState == 4" class="rc_tag" >
											<div v-if="project.projectState == 4" class="rc_mark_black">제안단계</div>
											<div v-if="project.recrEndDateDiff >= 0" class="rc_mark">+모집중</div>
										</div>
										{{project.projectNm}}
									</td>
									<td class="score prj-name">{{project.clientNm}}</td>
									<td class="score">{{project.projectStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{project.projectEndYyyymmdd | date('yyyy.MM.dd')}}</td>
									<td class="score">{{project.regDate | date('yyyy.MM.dd')}}</td>
									<td class="score">
										<span v-if="project.projectState == '4'"><div class="state us"></div>제 안 중</span>
										<span v-else-if="project.projectState == '2'"><div class="state ep"></div>수행예정</span>
										<span v-else-if="project.projectState == '9'"><div class="state cp"></div>수행완료</span>
										<span v-else><div class="state pf"></div>수 행 중</span>
									</td>
									<td class="score resume">
										<div class="mem">{{project.intvMberCnt}}명</div>
										<div class="btn" style="min-width: 110px;" @click="clickProjectDetail(project)">지원인재 보기 &#9654;</div>
									</td>
								</tr>
								
								<!-- 등록된 프로젝트가 없을 경우 -->
								<tr v-if="projectList.length <= 0">
									<td colspan="7" class="none">프로젝트가 없습니다!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- 페이징부분 -->
					<pagingComp :pageInfo="pageInfo" @page-click="goPage" />	
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			projectList : [],
			pageInfo : {},

			corpdiv : this.$store.state.userInfo.mberDivCd,
			
			srchFilter : {
				srcTxt : '',
				orderGubun : 1, 
				pageIndex : 1,
				projectState : '',
				searchGubun : 1,
			},
		}
	},

	components : {
		pagingComp, 
	},

	beforeMount() {
		this.selectIntvReqList();
	},

	mounted(){

    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		selectIntvReqList(div) {
			var param = this.srchFilter;
			param.pageUnit = 20;
			param.pageSize = 10;
			param.corpdiv = this.corpdiv;
			
			if(div) param.pageIndex = div;

			this.$.httpPost('/api/prj/sco/getScoPrjAppPrjList', param)
				.then((res) => {

					this.projectList = res.data.projectList;
					this.pageInfo = res.data.pageInfo;
	
				})
		},

		orderSort(div) {
			this.srchFilter.orderGubun = div;
			this.selectIntvReqList('1');
		},

		// 페이지 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.selectIntvReqList();
		},

		// 지원인재 보기
		clickProjectDetail(project) { 
			this.$router.push({name : 'PRJ402M02', params : { projectSeq : project.projectSeq}})
		}

	}
}
</script>